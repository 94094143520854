import MissingRequiredFields from 'components/MissingRequiredFields';
import SectionHeaderComponent from 'components/SectionHeaderSlice';
import React from 'react';

import { SectionHeaderSlice } from '../../types.generated';
import styles from './SectionHeader.module.scss';

export const SectionHeader = ({ slice }: { slice: SectionHeaderSlice }) => {
  const {
    title,
    mobile_alignment: mobileAlignment,
    desktop_alignment: desktopAlignment,
    underline,
  } = slice.primary;
  if (!title.length) return <MissingRequiredFields />;
  return (
    <section className={`p-body header-container ${styles.container}`}>
      <SectionHeaderComponent
        title={title}
        mobileAlignment={mobileAlignment}
        desktopAlignment={desktopAlignment}
        underline={underline}
      />
    </section>
  );
};
